import React from 'react'
import { hideAlert } from '../helpers/alertHelper'

function Alert(){
    return (
        <>
        <section id='globalAlert'> 
            <div className='fundo'>
                <div className='modal'>
                    <h2 id='globalAlertTitle'>.</h2>
                    <p id='globalAlertMessage'></p>
                    <button onClick={() => hideAlert()} id='globalAlertButton'>Ok</button>
                </div>
            </div>
        </section>
        </>
    )
}

export default Alert