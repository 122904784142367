import React, { useEffect, useState } from 'react'
import Api from '../../services/api'
import { Link, useHistory } from 'react-router-dom'
import { parseJwt } from '../../services/auth'
import { setAlertPropsAndShow } from '../../helpers/alertHelper'

function AdminLogin() {
  let history = useHistory()

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    const userToken = localStorage.getItem('userToken')

    if (!userToken) return

    if (parseJwt().user_type !== 'admin') return history.push('/home')

    if (parseJwt().user_type === 'admin') return history.push('/admin/home')
  }, [history])

  const loginApi = (e, login, password) => {
    e.preventDefault()

    Api.post('/session', {
      login,
      password,
      isAdmin: true,
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('userToken', response.data.token)
          checkUserTypeRedirectToHome(response)
        }
      })
      .catch((error) => {
        console.log(error)
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }

  const verifyIfUserIsCommon = (userToken) => {
    if (parseJwt(userToken).user_type !== 'admin') {
      setAlertPropsAndShow({ title: 'Ops!', message: 'Faça login na área de usuários comuns' })

      return history.push('/')
    }
    return
  }

  const checkUserTypeRedirectToHome = (response) => {
    verifyIfUserIsCommon(response.data.token)

    return (window.location.href = '/admin/home')
  }
  return (
    <>
      <section>
        <form onSubmit={(e) => loginApi(e, login, password)}>
          <input
            required
            placeholder="Login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          ></input>
          <input
            required
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <Link to={'/forgot-password'}>Esqueci minha senha</Link>
          <button type="submit">Entrar</button>
        </form>
      </section>
    </>
  )
}

export default AdminLogin
