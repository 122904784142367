import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../assets/imgs/logoLanding.png";

import whatsapp from "../assets/imgs/whatsapp.png";
import back from "../assets/imgs/back.png";
import Carousel from "react-elastic-carousel";

import * as S from "./styles";

import direita from "../assets/imgs/landing/direita.png";
import whatsappIcon from "../assets/imgs/whatsapp.svg";
import instagramIcon from "../assets/imgs/instagram.svg";
import facebookIcon from "../assets/imgs/facebook.png";

import "./landing.scss";
import "./plans.scss";

function Planos() {
  const history = useHistory();

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },

    { width: 961, itemsToShow: 3, itemsToScroll: 3 },
  ];
  return (
    <>
      <div className="plans-wrapper">
        <a
          className="whatsapp"
          target="_blank"
          href="https://api.whatsapp.com/send?phone=5519998086658"
        >
          <img src={whatsapp} alt="" />
        </a>
        <nav className="landing-header-wrapper">
          <img
            src={back}
            onClick={() => history.push("/")}
            className="mobile user"
          />
          <div className="container">
            <NavLink className="linkheader" to="/">
              <img src={logo} className="logoLanding" alt="vivamais" />
            </NavLink>
            <ul className="landing-header-buttons desktop">
              <NavLink activeClassName="navlink" to="/">
                {/* <li>SOBRE</li> */}
                <li>Página inicial</li>
              </NavLink>
              <NavLink activeClassName="navlink" to="/aluno">
                <li className="areaaluno">ÁREA DO ALUNO</li>
              </NavLink>
            </ul>
          </div>
        </nav>
        <section className="plans-header"></section>
        <section className="plans-container">
          <h1 className="plans-title"> Conheça nossos Planos </h1>

          {/* PERSONAL ONLINE INDIVIDUAL */}

          <h1 className="plans-title-h2"> Personal à Distância</h1>
          <div className="plan-description">
            Planilha de corrida e/ou treinamento de força personalizado mensal
            através do acesso a plataforma on-line. Feedback integral (direto
            com seu professor) para tirar dúvidas e/ou melhorar seu treino,
            sempre que você precisar. <br />
            <br />
            <span>
              Acesso a plataforma on-line com treinos personalizados, exclusivos
              e com mais de 400 vídeos e gifs didáticos.
            </span>
          </div>
          <div className="plans-flex">
            <Carousel breakPoints={breakPoints}>
              <div className="plan">
                <div className="plan-title">Personal à Distância</div>
                <div className="frequencia">
                  Plataforma On-line e acompanhamento exclusivo
                </div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  R$210,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="FD29071E0E0ECB388414DFB80E40D908"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal à Distância</div>
                <div className="frequencia">
                  Plataforma On-line e acompanhamento exclusivo
                </div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  R$190,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="4726B142080824BEE42E1FA5F41146D4"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal à Distância</div>
                <div className="frequencia">
                  Plataforma On-line e acompanhamento exclusivo
                </div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  R$175,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="6469FCCABBBB207EE4B6AF976A7C5C9A"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
            </Carousel>
          </div>

          {/* PERSONAL ONLINE INDIVIDUAL */}

          <h1 className="plans-title-h2"> Personal On-line Individual</h1>
          <div className="plan-description">
            Treino personalizado, por video, com acompanhamento ao vivo de um
            professor só pra você! Toda consultoria que você precisa para
            treinar respeitando suas vontades, necessídades e objetivos! <br />
            <br />
            <span>
              Acesso a plataforma on-line com treinos personalizados, exclusivos
              e com mais de 400 vídeos e gifs didáticos.
            </span>
          </div>
          <div className="plans-flex">
            <Carousel breakPoints={breakPoints}>
              <div className="plan">
                <div className="plan-title">Personal On-Line Individual</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  R$500,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="C1C51F2E63634CCCC4521F91700729BD"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>

              <div className="plan">
                <div className="plan-title">Personal On-Line Individual</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  R$450,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="A53883AAA5A56A4444DA3FB571886873"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>

              <div className="plan">
                <div className="plan-title">Personal On-Line Individual</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  R$420,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="7FF65BED6A6AEEE33412BFAB978128B2"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Individual</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  R$650,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="E7A5B21C5E5E632AA46CAF82489149B6"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Individual</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  R$620,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="F8D7DAE626260FBFF448AFAD1D85292A"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Individual</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  R$585,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="623BE7D21E1EB75334ED9FACEDBA7A8E"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
            </Carousel>
          </div>

          {/* PERSONAL ONLINE EM DUPLA */}

          <h1 className="plans-title-h2"> Personal On-line Duplas</h1>
          <div className="plan-description">
            Treino personalizado, por video, com acompanhamento ao vivo de um
            professor só pra você! Toda consultoria que você precisa para
            treinar respeitando suas vontades, necessídades e objetivos! <br />
            <br />
            <span>
              Acesso a plataforma on-line com treinos personalizados, exclusivos
              e com mais de 400 vídeos e gifs didáticos.
            </span>
          </div>
          <div className="plans-flex">
            <Carousel breakPoints={breakPoints}>
              <div className="plan">
                <div className="plan-title">Personal On-Line Duplas</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  R$290,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="5C46556FCECE249DD4362FBF651AD43E"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Duplas</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  R$270,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="85E9CC1A5D5D8A4994C29F893A762E7C"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Duplas</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  R$250,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="85E9CC1A5D5D8A4994C29F893A762E7C"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Duplas</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  R$400,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="75D3E4B49696121334AB6FA5B771C82E"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Duplas</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  R$375,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="B5975BAAE2E2589EE4B4EFA7C276B6C9"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Personal On-Line Duplas</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  R$350,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="B5975BAAE2E2589EE4B4EFA7C276B6C9"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
            </Carousel>
          </div>

          {/* TREINAMENTO ORIENTADO */}

          <h1 className="plans-title-h2"> Treinamento Orientado</h1>
          <div className="plan-description">
            Metodologia original que utiliza diversos métodos de treinamento
            (treinamento funcional, pilates solo, musculação e aeróbios) em
            apenas uma aula, com fichas individualizadas de treino e atendimento
            de excelência. <br />
            <br />
            <span>
              Adequamos o espaço disponível ou planejamos a montagem de uma
              academia em seu condomínio para a realização das atividades.
            </span>
          </div>
          <div className="plans-flex">
            <Carousel breakPoints={breakPoints}>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">1 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  100,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="6D120A325B5B7CAEE44CCF9909B9F12A"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">1 vez por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  90,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="8DF3F0E3B4B4E6A6643A1FBE270A68E9"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">1 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  80,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="6D7C9862A3A3B925546B5F99AAA2D62C"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  156,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="857C94E71010284774557FAA0122CA64"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  146,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="857C94E71010284774557FAA0122CA64"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">2 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  136,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="26B15A3EF0F0DECFF4279FA19FAB3B88"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  210,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="7DA90F2AF8F86EC554191FB00D6A55E6"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  200,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="C39251B27E7E7F9444016FAE0B1DA1E2"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">3 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  190,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="C39251B27E7E7F9444016FAE0B1DA1E2"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">4 vezes por semana</div>
                <div className="pagamento">Trimestral</div>
                <div className="valor">
                  250,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="8D747FC0FBFB372114D82FB683D3F7A0"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">4 vezes por semana</div>
                <div className="pagamento">Semestral</div>
                <div className="valor">
                  245,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="1423FA308A8A0CE994273F95AB009DE9"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
              <div className="plan">
                <div className="plan-title">Treinamento Orientado</div>
                <div className="frequencia">4 vezes por semana</div>
                <div className="pagamento">Anual</div>
                <div className="valor">
                  235,00<span>/ mês</span>
                </div>

                <div className="descricao">
                  <form
                    action="https://pagseguro.uol.com.br/pre-approvals/request.html"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="code"
                      value="1423FA308A8A0CE994273F95AB009DE9"
                    />
                    <input type="hidden" name="iot" value="button" />
                    <input
                      type="image"
                      src="https://stc.pagseguro.uol.com.br/public/img/botoes/assinaturas/209x48-assinar-assina.gif"
                      name="submit"
                      alt="Pague com PagSeguro - É rápido, grátis e seguro!"
                      width="209"
                      height="48"
                    />
                  </form>
                </div>
              </div>
            </Carousel>
          </div>
        </section>
        <footer className="landing-footer">
          <S.Direita src={direita} className="desktop" alt="apetrecho" />
          <div className="container">
            <img src={logo} className="logoLanding" alt="" />
            <S.SocialMedia>
              <a
                href="https://www.instagram.com/projetovivamaiss/"
                target="_blank"
              >
                <img src={instagramIcon} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/Projeto-Viva-Mais-1413255182251367"
                target="_blank"
              >
                <img src={facebookIcon} alt="Facebook" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5519998086658"
                target="_blank"
              >
                <img src={whatsappIcon} alt="Whatsapp" />
              </a>
            </S.SocialMedia>
            <span>contato@projetovivamais.com.br</span>
            <span>© 2021 Projeto Viva Mais</span>
            <ul className="landing-footer-socials"></ul>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Planos;
