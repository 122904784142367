import React from 'react'

import Tabela from '../components/home/tabela'

function Home() {
  return (
    <>
      <div className="atividadesSection">
        <Tabela />
      </div>
    </>
  )
}

export default Home
