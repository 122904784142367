import React, { useEffect, useState } from "react";
import Api from "../services/api";
import { Link, useHistory } from "react-router-dom";
import { parseJwt } from "../services/auth";
import { setAlertPropsAndShow } from "../helpers/alertHelper";
import logo from "../assets/imgs/logo.png";
import back from "../assets/imgs/back.svg";

function Login() {
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) return;

    if (parseJwt().user_type !== "admin") return history.push("/atividades");

    if (parseJwt().user_type === "admin") return history.push("/admin/home");
  }, [history]);

  const login = (e, email, password) => {
    e.preventDefault();
    Api.post("/session", {
      email,
      password,
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("userToken", response.data.token);
          redirectToHome();
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({
          title: "Ops!",
          message: error.response.data.error,
        });
      });
  };

  const redirectToHome = () => {
    return (window.location.href = "/atividades");
  };
  return (
    <>
      <section className="Login">
        <img
          src={back}
          onClick={() => history.push("/")}
          className="mobile user"
        />
        <form className="LoginForm" onSubmit={(e) => login(e, email, password)}>
          <img alt="logo" className="logo" src={logo} />
          <input
            type="email"
            placeholder="E-mail"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Entrar</button>

          {/* <Link className="LoginButton" to={'/forgot-password'}>
            <button renderAs="button">Esqueci minha senha</button>
          </Link> */}

          <br />
        </form>
      </section>
    </>
  );
}

export default Login;
