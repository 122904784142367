import React from 'react'
import Users from './users'
import './admin.scss'


function AdminHome() {
  
  return (
    <>

      <Users />
    </>
  )
}

export default AdminHome
