import React, { useEffect, useState } from 'react'
import { cpfMask } from '../helpers/MaskHelper'
import Api from '../services/api'

import { setAlertPropsAndShow } from '../helpers/alertHelper'
import { useHistory } from 'react-router-dom'

function ForgotPassword() {
  let history = useHistory()

  const [cpf, setCpf] = useState('')

  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    if (userToken)
      return setAlertPropsAndShow({
        title: 'Aviso',
        message:
          'Você está logado com um usuário, caso solicite uma nova senha sua sessão será encerrada.',
      })
  }, [])

  const forgotPassword = (cpf, e) => {
    if (e) e.preventDefault()
    if (!cpf) return setAlertPropsAndShow({ title: 'Ops!', message: 'O campo CPF é obrigatório!' })

    Api.put('/session', {
      cpf,
    })
      .then((response) => {
        if (response.status === 200) {
          alertUserAndRedirectToLogin()
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }

  const alertUserAndRedirectToLogin = () => {
    localStorage.removeItem('userToken')

    setAlertPropsAndShow({
      title: 'Sucesso',
      message: 'Verifique seu email, enviamos uma nova senha! :)',
      onClickFunction: () => {
        history.push('/')
      },
    })
  }

  return (
    <>
      <section>
        <form onSubmit={(e) => forgotPassword(cpf, e)}>
          <input
            required
            type="text"
            placeholder="Seu CPF"
            value={cpf}
            onChange={(e) => setCpf(cpfMask(e.target.value))}
          />
          <p>Você receberá um e-mail com suas credenciais</p>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault()
              forgotPassword(cpf)
            }}
          >
            Continuar
          </a>
        </form>
      </section>
    </>
  )
}

export default ForgotPassword
