import axios from 'axios'

export default axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `https://projetovivamais.herokuapp.com`
      : `https://projetovivamais.herokuapp.com`,
  headers: {
    Authorization: localStorage.getItem('userToken'),
    'Access-Control-Allow-Origin': '*',
  },
})
