import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/imgs/logo.png";

import archives from "../assets/imgs/archives.png";
import videos from "../assets/imgs/videos.png";
import atividades from "../assets/imgs/atividades.png";

function Header() {
  return (
    <>
      <div className="header">
        <img alt="logo" className="logo" src={logo} />
        {/* 
        <p className="welcome">
          <span className="welcomeBig">
            {' '}
            Olá, <strong>{decodeURIComponent(escape(userName))}</strong>!
          </span>
          <br />
          Você está na
          <strong> área do aluno</strong>.
        </p> */}
        <nav className="headerNav">
          <NavLink activeClassName="navlink" to="/atividades">
            <button className="desktop">Ficha de Treino</button>
            <img
              className="header-img mobile"
              alt="atividades"
              src={atividades}
            />
          </NavLink>
          <NavLink activeClassName="navlink" to="/arquivos">
            <button className="desktop">Arquivos Pessoais</button>
            <img
              className="header-img mobile"
              alt="atividades"
              src={archives}
            />
          </NavLink>
          <NavLink activeClassName="navlink" to="/videos">
            <button className="desktop">Lista de Vídeos</button>
            <img className="header-img mobile" alt="atividades" src={videos} />
          </NavLink>
          {/* <NavLink activeClassName="navlink" to="/corrida">
            <button className="desktop">Planilha de Corrida</button>
            <img className="header-img mobile" alt="atividades" src={corrida}/>
          </NavLink> */}
          {/* <NavLink activeClassName="navlink" to="/atividades">
            <button>Exames/Avaliações</button>
          </NavLink> */}
        </nav>
        {/* <h2>Menu</h2>
        <ul className="navMenu">
          <li>
            <NavLink to={'/home'}>Home</NavLink>
          </li>
          <li>Meu treino</li>
          <li>Vídeos</li>
          <li>Minha conta</li>
        </ul> */}
      </div>
    </>
  );
}

export default Header;
