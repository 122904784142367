import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { parseJwt } from '../../services/auth'
import logo from '../../assets/imgs/logo.png'

function AdminHeader() {
  let history = useHistory()

  const [userName, setUserName] = useState('')

  useEffect(() => {
    if (localStorage.getItem('userToken')) return setUserName(parseJwt().name)
  }, [])

  const logout = () => {
    localStorage.removeItem('userToken')

    return history.push('/')
  }

  return (
    <>
      <div className="header">
        <img alt="logo" className="logo" src={logo} />
        {/* 
        <p className="welcome">
          <span className="welcomeBig">
            {' '}
            Olá, <strong>{decodeURIComponent(escape(userName))}</strong>!
          </span>
          <br />
          Você está na
          <strong> área do aluno</strong>.
        </p> */}
        <nav className="headerNav">
          <p>Olá {decodeURIComponent(escape(userName))}</p>
          <button onClick={() => logout()}>Sair</button>
        </nav>
        {/* <h2>Menu</h2>
        <ul className="navMenu">
          <li>
            <Link to={'/home'}>Home</Link>
          </li>
          <li>Meu treino</li>
          <li>Vídeos</li>
          <li>Minha conta</li>
        </ul> */}
      </div>
    </>
  )
}

export default AdminHeader
