import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Api from '../../services/api'
import { parseJwt } from '../../services/auth'
import { setAlertPropsAndShow } from '../../helpers/alertHelper'
import data from '../../videoData.json'
import './training.scss'

function UpdateTraining(props) {
  let history = useHistory()
  const [trainings, setTrainings] = useState([])
  const [currentTraining, setCurrentTraining] = useState(null)
  const [currentPart, setCurrentPart] = useState(null)
  const [currentSequence, setCurrentSequence] = useState(null)
  const [trainingName, setTrainingName] = useState('')
  const [trainingTime, setTrainingTime] = useState('')
  const [partName, setPartName] = useState('')
  const [partTime, setPartTime] = useState('')
  const [sequenceName, setSequenceName] = useState('')
  const [sequenceObservation, setSequenceObservation] = useState('')
  const [exerciseSeries, setExerciseSeries] = useState('')
  const [exerciseLoad, setExerciseLoad] = useState('')
  const [exerciseVideoId, setExerciseVideoId] = useState(0)

  const [deletedTraining, setDeletedTraining] = useState(false)
  const [deletedPart, setDeletedPart] = useState(false)
  const [deletedSequence, setDeletedSequence] = useState(false)
  const [deletedExercise, setDeletedExercise] = useState(false)



  useEffect(() => {
    getAllTheTrainings()
    console.log(currentTraining)
 
  }, [currentTraining, deletedTraining, deletedPart, deletedExercise, deletedSequence])

  useEffect(() => {
    if (!props.location.user_id) return history.push({ pathname: '/admin/home' })
  }, [props.location.user_id, history])

  
  const getAllTheTrainings = () => {
    Api.get(`/training/${props.location.user_id}`).then((response) => {
      setTrainings(response.data)
    })
  }

  const deleteTraining = (id) => {
    const confirm = window.confirm("Tem certeza de que deseja deletar esse treino?");
    if(!confirm) {
      return
    }
    Api.delete(`/training/${id}`)
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({ title: 'Sucesso', message: 'Treino deletado com sucesso' })
          setDeletedTraining(!deletedTraining)
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }

  const addTraining = (e, user_id, name, time) => {
    e.preventDefault()
    Api.post('/training', { user_id, name, time })
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({ title: 'Sucesso', message: 'Treino adicionado com sucesso' })
        }
        getAllTheTrainings()
      })
      .catch((error) => console.log(error))
  }
  const deletePart = (id) => {
  const confirm = window.confirm("Tem certeza de que deseja deletar essa parte?");
  if(!confirm) {
    return
  }
    Api.delete(
      `/part/${id}`,
    )
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({
          //   title: 'Sucesso',
          //   message: 'A parte do treino foi deletada com sucesso',
          // })
          setDeletedPart(!deletedPart)
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }
  const addPartToTraining = (e, training_id, name, time) => {
    console.log(training_id)
    e.preventDefault()
    Api.post('/part', { training_id, name, time })
      .then((response) => {
        if (response.status === 200) {
          setAlertPropsAndShow({ title: 'Sucesso', message: 'Parte adicionada com sucesso' })
        }
        getAllTheTrainings()
      })
      .catch((error) => console.log(error))
  }
  const deleteSequence = (id) => {
    const confirm = window.confirm("Tem certeza de que deseja deletar essa sequencia?");
    if(!confirm) {
      return
    }
    Api.delete(`/sequence/${id}`)
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({ title: 'Sucesso', message: 'Sequencia deletada com sucesso' })
          setDeletedSequence(!deletedSequence)
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }

  const addSequenceToPart = (e, part_id, name, time) => {
    e.preventDefault()
    Api.post('/sequence', { part_id, name, time })
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({ title: 'Sucesso', message: 'Sessão adicionada com sucesso' })
        }
        getAllTheTrainings()
      })
      .catch((error) => console.log(error))
  }
  const deleteExercise = (id) => {
    const confirm = window.confirm("Tem certeza de que deseja deletar essa exercicio?");
    if(!confirm) {
      return
    }
    Api.delete(`/exercise/${id}`)
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({ title: 'Sucesso', message: 'Sequencia deletada com sucesso' })
          setDeletedExercise(!deletedExercise)
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }

  const addExerciseToSequence = (e, sequence_id, series, load, video_id) => {
    e.preventDefault()
    Api.post('/exercise', { sequence_id, series, load, video_id })
      .then((response) => {
        if (response.status === 200) {
          // setAlertPropsAndShow({ title: 'Sucesso', message: 'Exercício adicionado com sucesso' })
        }
        getAllTheTrainings()
      })
      .catch((error) => console.log(error))
  }



  return (
    <>
     
      <div id="training" className="admin-training-section">
      <button className="back-button" onClick={history.goBack}>Voltar</button>
      <h1 className="training-name"> Treinos de {props.location.name}</h1>
        <div className="bloco">

      
        <div className="training-headers">
          <div className="training-button-wrapper">
            {
            trainings.map((training) => (
              
              <>
                <button
                  className="training-button"
                  key={training.id}
                  onClick={() => setCurrentTraining(training.id)}
                >
                  {training.name}
                </button>
                <button className="training-delete" onClick={() => deleteTraining(training.id)}>
                  X
                </button>
                
              </>
            ))}
          </div>
        </div>
        <div></div>
        <form
          className="training-form"
          onSubmit={(e) => addTraining(e, props.location.user_id, trainingName, trainingTime)}
        >
          <input
            className="training-input"
            onChange={(e) => setTrainingName(e.target.value)}
            placeholder="Nome do treino"
            required
          />
          <input
            className="training-input"
            onChange={(e) => setTrainingTime(e.target.value)}
            placeholder="Tempo do treino"
            required
          />
          <button type="submit">+ Adicionar Treino</button>
        </form>

        </div>
        { 
        trainings
          ? trainings.map((training) =>
              training.id === currentTraining ? (
                <>
                                <h1 className="training-name"> {training.name}</h1>

            <div className="bloco">
                  <div className="training-headers">
                    {training.Parts.sort(function(a, b) {
  if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
 }).map((part) => (
                      <>
                 
                        <div className="training-button-wrapper">
                          <button
                            className="training-button"
                            onClick={() => setCurrentPart(part.id)}
                          >
                            {part.name}
                          </button>
                          <button className="training-delete" onClick={() => deletePart(part.id)}>
                            X
                          </button>
                        </div>
                      </>
                    ))}
                  </div>
                  <div></div>
                  <form
                    className="training-form"
                    onSubmit={(e) => addPartToTraining(e, training.id, partName, partTime)}
                  >
                    <input
                      className="training-input"
                      onChange={(e) => setPartName(e.target.value)}
                      placeholder="Nome da parte"
                      required
                    />
                    <input
                      className="training-input"
                      onChange={(e) => setPartTime(e.target.value)}
                      placeholder="Tempo da parte"
                      required
                    />
                    <button type="submit">+ Adicionar Parte</button>
                  </form>
                  </div>

                  {training.Parts
                    ? training.Parts.map((part) =>
                        part.id === currentPart ? (
                          <>
                             <h1 className="training-name">Sequências da {part.name}</h1>
                                  <div className="bloco">
                         
                            <div className="training-headers">
                              {part.Sequences.sort(function(a, b) {
  if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
 }).map((sequence) => (
                                <>
                                  <div className="training-button-wrapper">
                                    <button
                                      className="training-button"
                                      onClick={() => setCurrentSequence(sequence.id)}
                                    >
                                      {sequence.name}
                                    </button>
                                    <button
                                      className="training-delete"
                                      onClick={() => deleteSequence(sequence.id)}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              ))}
                            </div>
                            <div></div>
                            <form
                              className="training-form"
                              onSubmit={(e) =>
                                addSequenceToPart(e, part.id, sequenceName, sequenceObservation)
                              }
                            >
                              <input
                                className="training-input"
                                onChange={(e) => setSequenceName(e.target.value)}
                                placeholder="Nome da sessão"
                                required
                              />
                              <input
                                className="training-input"
                                onChange={(e) => setSequenceObservation(e.target.value)}
                                placeholder="Observação da sessão"
                                required
                              />
                              <button type="submit">+ Adicionar Sessão</button>
                            </form>
                            </div>
                            {part.Sequences
                              ? part.Sequences.sort(function(a, b) {
                                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                               }).map((sequence) =>
                                  sequence.id === currentSequence ? (
                                    <>
                                                               <h1 className="training-name">Exercícios da sequência {sequence.name}</h1>
                                                   <div className="bloco">
                                                   <div className="training-button-wrapper">
                                      {sequence.Exercises.map((exercise) =>
                                         
                                        data.map((video) =>
                                          video.id === exercise.video_id ? (
                                            <>
                                                      
                                   
                                            <button className="training-button exercisebt" key={video.id}>{video.name}</button>
                                            <button
                                            className="training-delete"
                                            onClick={() => deleteExercise(exercise.id)}
                                          >X</button>
                                          <div className="load">{exercise.load}</div>
                                          <div></div>
                                          <div className="load">{exercise.series}</div>
                                          <div></div>
                                            </>
                                          ) : null,
                                        ),
                                        )}
                                        </div>
                                      <div></div>
                                      <form className="exercicises-form"
                                        onSubmit={(e) =>
                                          addExerciseToSequence(
                                            e,
                                            sequence.id,
                                            exerciseSeries,
                                            exerciseLoad,
                                            exerciseVideoId,
                                          )
                                        }
                                      >
                                        <input
                                          className="training-input"
                                          onChange={(e) => setExerciseSeries(e.target.value)}
                                          placeholder="Series do exercício"
                                          required
                                        />
                                        <input
                                          className="training-input"
                                          onChange={(e) => setExerciseLoad(e.target.value)}
                                          placeholder="Peso do exercicio"
                                          required
                                        />
                                        <br />
                                        <select
                                          className="training-input"
                                          onChange={(e) => setExerciseVideoId(e.target.value)}
                                        >
                                          <option>VideoId</option>
                                          {data.map((video) => (
                                            <option key={video.id} value={video.id}>
                                              {video.name}
                                            </option>
                                          ))}
                                        </select>
                                        <button type="submit">+ Adicionar Exercício</button>
                                      </form>
                                      </div>
                                    </>
                                  ) : null,
                                )
                              : null}
                          </>
                        ) : null,
                      )
                    : null}
                </>
              ) : null,
            )
          : null}
      </div>
    </>
  )
}

export default UpdateTraining
