import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { parseJwt } from "./services/auth";
import AdminHeader from "./components/admin/header";
import Header from "./components/header";
import Alert from "./components/alert";

import Register from "./pages/register";
import Videos from "./pages/videos";
import ForgotPassword from "./pages/forgotPassword";
import Home from "./pages/home";
import Corrida from "./pages/corrida";
import UserConfirmation from "./pages/userConfirmation";
import NotFound from "./pages/notFound";

import AdminHome from "./pages/admin/home";
import UpdateTraining from "./pages/admin/training";
import UpdatePDF from "./pages/admin/pdf";
import AdminLogin from "./pages/admin/login";
import Users from "./pages/admin/users";
import Login from "./pages/login";
import Landing from "./pages/landing";
import Planos from "./pages/planos";
import Archives from "./pages/archives";

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <div className="appAluno">
        <AdminHeader />
        <Route
          {...rest}
          render={(props) => {
            if (!localStorage.getItem("userToken")) {
              return (
                <Redirect
                  to={{ pathname: "/", state: { from: props.location } }}
                />
              );
            } else if (parseJwt().user_type === "admin") {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/atividades",
                    state: { from: props.location },
                  }}
                />
              );
            }
          }}
        />
      </div>
    </>
  );
};

const CommonRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <div className="appAluno">
        <Header />
        <Route
          {...rest}
          render={(props) => {
            if (!localStorage.getItem("userToken")) {
              return (
                <Redirect
                  to={{ pathname: "/", state: { from: props.location } }}
                />
              );
            } else if (parseJwt().user_type === "common") {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/admin/home",
                    state: { from: props.location },
                  }}
                />
              );
            }
          }}
        />
      </div>
    </>
  );
};

export default function Routes() {
  return (
    <BrowserRouter>
      <Alert />
      <Switch>
        <Route path={"/"} exact component={Landing} />
        <Route path={"/planos"} exact component={Planos} />
        <Route path={"/aluno"} exact component={Login} />

        <CommonRoute path={"/atividades"} exact component={Home} />

        <CommonRoute path={"/videos"} exact component={Videos} />
        <CommonRoute path={"/corrida"} exact component={Corrida} />
        <CommonRoute path={"/confirmacao"} exact component={UserConfirmation} />
        <CommonRoute path={"/arquivos"} exact component={Archives} />

        <Route exact path={"/admin"} component={AdminLogin} />
        <AdminRoute path={"/cadastro"} component={Register} />
        <Route path={"/forgot-password"} component={ForgotPassword} />

        <AdminRoute exact path={"/admin/home"} component={AdminHome} />
        <AdminRoute exact path={"/admin/users"} component={Users} />
        <AdminRoute
          exact
          path={"/admin/editar/treino"}
          component={UpdateTraining}
        />
        <AdminRoute exact path={"/admin/editar/pdf"} component={UpdatePDF} />

        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
