import React, { useState } from "react";

function Testimonial({ children }) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <p class={expand ? "expanded" : "nonexpanded"}>{children}</p>
      <span
        className="vermais"
        onClick={() => {
          setExpand(!expand);
        }}
      >
        {expand ? "Ver menos" : "Ver mais"}
      </span>
    </>
  );
}

export default Testimonial;
