import React from 'react'
import { useHistory } from 'react-router-dom'

function NotFound() {
  let history = useHistory()

  return (
    <>
      <div className="videosSection">
        <h1> 404 </h1>
        <p>Página não encontrada! :(</p>
        <button onClick={() => history.push('/')}>Voltar para o site</button>
      </div>
    </>
  )
}

export default NotFound
