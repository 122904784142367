import React, { useRef, useState, useEffect } from 'react'
import ModalVideo from 'react-modal-video'
import Data from '../videoData.json'
import { BiSearchAlt } from 'react-icons/bi'
import { FaVideo } from 'react-icons/fa'
import { FaUser } from 'react-icons/fa'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../components/pagination'
import { parseJwt } from '../services/auth'
import { useHistory } from 'react-router-dom'
import LoadingSpin from 'react-loading-spin';
import DEFAULT_IMG from '../assets/imgs/default.png'

function Movie() {
  const [videos, setVideos] = useState([])
  const [search, setSearch] = useState('')
  const [filteredVideos, setFilteredVideos] = useState([])


  const [isOpen, setOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState(false)
  let history = useHistory()
  useEffect(() => {
    setVideos(Data)
  
  }, [filteredVideos,  search])

  let [page, setPage] = useState(1)
  const PER_PAGE = 8

  const count = Math.ceil(filteredVideos.length / PER_PAGE)
  const _DATA = usePagination(filteredVideos, PER_PAGE, videos)

  const handleChange = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  const changeSearch = () => {
    _DATA.jump(0)
  }
  const logout = () => {
    localStorage.removeItem('userToken')

    return history.push('/')
  }
  useEffect(() => {
    setVideos(Data)
  
  }, [filteredVideos,  search])

  useEffect(() => {
    setFilteredVideos(
      videos.filter((video) => video.name.toLowerCase().includes(search.toLowerCase())),
    )
  }, [search, videos])
  const [userName, setUserName] = useState('')

  useEffect(() => {
    if (localStorage.getItem('userToken')) return setUserName(parseJwt().name)
  }, [])

  return (

    <>
    	<ModalVideo oncontextmenu="return false;" channel='custom' autoplay isOpen={isOpen} url={videoUrl} onClose={() => setOpen(false)} />
      <div className="videosSection">
        <div className="videosHeader">
          <div className="title">
            <div>
              <FaVideo className="iconVideosHeader" />
              <h1>Lista de Vídeos</h1>
            </div>
            <div>
            <FaUser className="iconVideosHeader desktop" />
            <strong className="desktop">{decodeURIComponent(escape(userName))}</strong>
            <button onClick={() => logout()}>Sair</button>
          </div>
          </div>
          <div className="searchHolder">
            <div className="searchIcon">
              <BiSearchAlt />
            </div>
            <input
              type="text"
              placeholder="Procurar"
              onChange={(e) => {
                setSearch(e.target.value)
                changeSearch()
              }}
            />
          </div>
        </div>
        <Pagination count={count} page={page} onChange={handleChange} className="pagination" />
        <ul className="video-ul">
          {_DATA.currentData().map((video, id) => (
            <VideoDetail key={id} {...video} />
          ))}
        </ul>
      </div>
    </>
    
  )

  

function VideoDetail(props) {
  const { id, name, src, src2} = props
  const videoSet = () => {
    setOpen(true);
    setVideoUrl(`${process.env.PUBLIC_URL}${src}`)
  }
  const [loading, setLoading] = useState(true);
  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= 1) {
      setLoading(false);
    }
  }
  
  return (
    <>
      
      <li className="videoLi" key={id} onClick={()=> {
   
   videoSet()
                            }}>

 <div className="loading-spin" style={{opacity: loading ? "1" : "0"}}>
 <LoadingSpin
            duration = '2s'
            width = '5px'
            timingFunction = 'ease-in-out'
            direction = 'alternate'
            size = '50px'
            primaryColor = '#024962'
            secondaryColor = '#012946'
        />
    </div>
        <img className="thumb-gif" onLoad={imageLoaded}  src={src2} onError={(e)=>{e.target.src=DEFAULT_IMG}} alt="" />  
    
        <span className="videoid">
          <strong>({id}/385)</strong> {name}
        </span>

      </li>

    </>
  )
}
} 
export default Movie
