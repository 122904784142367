import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    padding: 0;
}
#globalAlert {
    display: none;
    position: fixed;
    background-color: #00000020;
    height: 100vh;
    width: 100vw;
    z-index:10;
    justify-content: center;
    align-items:center;
}
`
