import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/imgs/logoLanding.png";
import { useHistory } from "react-router-dom";

import phone from "../assets/imgs/landing/phone2.png";
import user from "../assets/imgs/userIcon.png";
import personal3 from "../assets/imgs/distancia.png";
import esquerda from "../assets/imgs/personal-2.png";
import personal from "../assets/imgs/personal-1.png";

import personal5 from "../assets/imgs/personal-5.png";
import personal4 from "../assets/imgs/personal-4.png";

import direita from "../assets/imgs/landing/direita.png";
import whatsappIcon from "../assets/imgs/whatsapp.svg";
import instagramIcon from "../assets/imgs/instagram.svg";
import facebookIcon from "../assets/imgs/facebook.png";

import "./landing.scss";
import whatsapp from "../assets/imgs/whatsapp.png";
import Carousel from "react-elastic-carousel";

import * as S from "./styles";
import Testimonial from "../components/testimonial";

function Landing() {
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    console.log(carouselRef.current);
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(3);
    }
  };

  const history = useHistory();

  return (
    <>
      <a
        className="whatsapp"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=5519998086658"
      >
        <img src={whatsapp} alt="" />
      </a>
      <div className="landing-wrapper">
        <nav className="landing-header-wrapper">
          <img
            src={user}
            onClick={() => history.push("/aluno")}
            className="mobile user"
          />
          {/* <img
            className="landing-header-grandient"
            src={headerGradient}
            alt=""
          /> */}
          <div className="container">
            <NavLink className="linkheader" to="/">
              <img src={logo} className="logoLanding" alt="vivamais" />
            </NavLink>

            <ul className="landing-header-buttons desktop">
              <NavLink activeClassName="navlink" to="/planos">
                {/* <li>SOBRE</li> */}
                <li>Conheça os planos</li>
              </NavLink>
              <NavLink activeClassName="navlink" to="/aluno">
                <li className="areaaluno">ÁREA DO ALUNO</li>
              </NavLink>
            </ul>
          </div>
        </nav>
        <section className="landing-header">
          <div className="landing-header-container">
            <div className="landing-header-hero">
              <h1>
                Saúde e movimento,
                <br />
                onde você estiver.
              </h1>
              <p>
                Viver bem e mais. O <strong>Projeto Viva Mais</strong> tem o
                compromisso de elevar sua motivação e seu nível de atividade
                fisíca, para manter uma rotina com saúde, qualidade de vida e
                movimento onde quer que você esteja.
              </p>
              <NavLink className="linkheader" to="/planos">
                <button>Conheça os planos</button>
              </NavLink>
            </div>
          </div>
        </section>
        <section className="landing-section1">
          <div className="landing-section1-caroussel">
            <div className="landing-section1-caroussel-inner">
              <div className="caroussel-text">
                <>
                  <h2>
                    Plataforma de <br />
                    Treinamento on-line
                  </h2>
                  <p>
                    Onde quer que você esteja! Para manter os alunos sempre
                    motivados o <strong>Projeto Viva Mais</strong> precisou
                    crescer e ocupar o mundo virtual. Assim nasceu uma
                    plataforma com{" "}
                    <strong>treinos personalizados, exclusivos</strong> e com
                    <strong>
                      {" "}
                      mais de 400 vídeos explicativos e gifs didáticos
                    </strong>{" "}
                    para ajudar o aluno a treinar e realizar os exercícios
                    propostos em qualquer lugar, seja nos equipamentos
                    disponiveis na academia de sua casa/condomínio, ou
                    utilizando o peso do próprio corpo.
                  </p>
                </>
              </div>

              <button onClick={() => history.push("/planos")} className="bt1">
                Conheça os planos
              </button>
            </div>
            <img className="phone" src={phone} alt="Celular" />
          </div>
        </section>
        <hr className="mobile" />

        <section className="landing-section2">
          <S.Modalidade>
            <S.ImageSide>
              <img className="desktop" src={personal3} alt="treino on-line" />
            </S.ImageSide>
            <S.TextSide>
              <S.TextHeading>
                <S.TextHeadingArrow>
                  <svg
                    width="41"
                    height="83"
                    viewBox="0 0 41 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.1972 82.9996C33.322 83.0026 32.4572 82.8072 31.6665 82.4276C30.8758 82.048 30.1791 81.494 29.6278 80.8062L1.33267 45.2382C0.471036 44.1774 0 42.8469 0 41.4739C0 40.1008 0.471036 38.7703 1.33267 37.7096L30.6237 2.14151C31.618 0.930909 33.0469 0.169608 34.596 0.0250844C36.145 -0.119439 37.6874 0.364654 38.8837 1.37087C40.0801 2.37708 40.8324 3.82299 40.9752 5.39051C41.118 6.95803 40.6396 8.51876 39.6453 9.72936L13.4591 41.5035L38.7665 73.2777C39.4829 74.1478 39.938 75.2074 40.0778 76.331C40.2177 77.4547 40.0366 78.5954 39.5559 79.6181C39.0752 80.6409 38.3151 81.5029 37.3654 82.1021C36.4157 82.7014 35.3163 83.0128 34.1972 82.9996Z"
                      fill="#009b9d"
                    />
                  </svg>
                </S.TextHeadingArrow>
                <S.TextHeadingContent>
                  <h2>Personal à Distância</h2>
                  <h3>Corrida e/ou Treinamento de Força</h3>
                  <span>Treino indivídual</span>
                </S.TextHeadingContent>
              </S.TextHeading>
              <img
                className="mobile imgmobile"
                src={personal3}
                alt="treino on-line"
              />
              <S.Description>
                Planilha de corrida e/ou treinamento de força personalizado
                mensal através do acesso a plataforma on-line. Feedback integral
                (direto com seu professor) para tirar dúvidas e/ou melhorar seu
                treino, sempre que você precisar.
                <br />
                <br />
                <strong>
                  Acesso a plataforma on-line com treinos personalizados,
                  exclusivos e com mais de 400 vídeos e gifs didáticos.
                </strong>
                <button onClick={() => history.push("/planos")} className="bt1">
                  Conheça os planos
                </button>
              </S.Description>
            </S.TextSide>
          </S.Modalidade>
          <hr className="mobile" />
          <S.Modalidade>
            <S.TextSide>
              <S.TextHeading revert>
                <S.TextHeadingContent revert>
                  <h2>Personal Presencial</h2>
                  <h3>Aulas em domícilio, academias e parques</h3>
                  <span>Treino indivídual ou em dupla</span>
                </S.TextHeadingContent>
                <S.TextHeadingArrow revert>
                  <svg
                    width="41"
                    height="83"
                    viewBox="0 0 41 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.1972 82.9996C33.322 83.0026 32.4572 82.8072 31.6665 82.4276C30.8758 82.048 30.1791 81.494 29.6278 80.8062L1.33267 45.2382C0.471036 44.1774 0 42.8469 0 41.4739C0 40.1008 0.471036 38.7703 1.33267 37.7096L30.6237 2.14151C31.618 0.930909 33.0469 0.169608 34.596 0.0250844C36.145 -0.119439 37.6874 0.364654 38.8837 1.37087C40.0801 2.37708 40.8324 3.82299 40.9752 5.39051C41.118 6.95803 40.6396 8.51876 39.6453 9.72936L13.4591 41.5035L38.7665 73.2777C39.4829 74.1478 39.938 75.2074 40.0778 76.331C40.2177 77.4547 40.0366 78.5954 39.5559 79.6181C39.0752 80.6409 38.3151 81.5029 37.3654 82.1021C36.4157 82.7014 35.3163 83.0128 34.1972 82.9996Z"
                      fill="#009b9d"
                    />
                  </svg>
                </S.TextHeadingArrow>
              </S.TextHeading>
              <img
                className="mobile imgmobile"
                src={personal}
                alt="treino on-line"
              />
              <S.Description revert>
                Treino personalizado presencial com acompanhamento de um
                professor só pra você! Levamos todo o material de treino, caso
                você não possua. Toda consultoria que você precisa para treinar
                respeitando suas vontades, necessídades e objetivos!
                <br />
                <br />
                <strong>
                  Acesso a plataforma on-line com treinos personalizados,
                  exclusivos e com mais de 400 vídeos e gifs didáticos.
                </strong>
                <button onClick={() => history.push("/planos")} className="bt1">
                  Conheça os planos
                </button>
              </S.Description>
            </S.TextSide>
            <S.ImageSide>
              <img className="desktop" src={personal} alt="treino on-line" />
            </S.ImageSide>
          </S.Modalidade>
          <hr className="mobile" />
          <S.Modalidade>
            <S.ImageSide>
              <img className="desktop" src={esquerda} alt="treino on-line" />
            </S.ImageSide>
            <S.TextSide>
              <S.TextHeading>
                <S.TextHeadingArrow>
                  <svg
                    width="41"
                    height="83"
                    viewBox="0 0 41 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.1972 82.9996C33.322 83.0026 32.4572 82.8072 31.6665 82.4276C30.8758 82.048 30.1791 81.494 29.6278 80.8062L1.33267 45.2382C0.471036 44.1774 0 42.8469 0 41.4739C0 40.1008 0.471036 38.7703 1.33267 37.7096L30.6237 2.14151C31.618 0.930909 33.0469 0.169608 34.596 0.0250844C36.145 -0.119439 37.6874 0.364654 38.8837 1.37087C40.0801 2.37708 40.8324 3.82299 40.9752 5.39051C41.118 6.95803 40.6396 8.51876 39.6453 9.72936L13.4591 41.5035L38.7665 73.2777C39.4829 74.1478 39.938 75.2074 40.0778 76.331C40.2177 77.4547 40.0366 78.5954 39.5559 79.6181C39.0752 80.6409 38.3151 81.5029 37.3654 82.1021C36.4157 82.7014 35.3163 83.0128 34.1972 82.9996Z"
                      fill="#009b9d"
                    />
                  </svg>
                </S.TextHeadingArrow>
                <S.TextHeadingContent>
                  <h2>Personal On-Line</h2>
                  <h3>Aulas por vídeo em tempo real</h3>
                  <span>Treino indivídual ou em dupla</span>
                </S.TextHeadingContent>
              </S.TextHeading>
              <img
                className="mobile imgmobile"
                src={esquerda}
                alt="treino on-line"
              />
              <S.Description>
                Treino personalizado, por video, com acompanhamento ao vivo de
                um professor só pra você! Toda consultoria que você precisa para
                treinar respeitando suas vontades, necessídades e objetivos!
                <br />
                <br />
                <strong>
                  Acesso a plataforma on-line com treinos personalizados,
                  exclusivos e com mais de 400 vídeos e gifs didáticos.
                </strong>
                <button onClick={() => history.push("/planos")} className="bt1">
                  Conheça os planos
                </button>
              </S.Description>
            </S.TextSide>
          </S.Modalidade>
          <hr className="mobile" />
          <S.Modalidade>
            <S.TextSide>
              <S.TextHeading revert>
                <S.TextHeadingContent revert>
                  <h2>Treinamento Orientado</h2>
                  <h3>Atividades para condomínios</h3>
                  <span>Grupos de até 6 pessoas por professor</span>
                </S.TextHeadingContent>
                <S.TextHeadingArrow revert>
                  <svg
                    width="41"
                    height="83"
                    viewBox="0 0 41 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.1972 82.9996C33.322 83.0026 32.4572 82.8072 31.6665 82.4276C30.8758 82.048 30.1791 81.494 29.6278 80.8062L1.33267 45.2382C0.471036 44.1774 0 42.8469 0 41.4739C0 40.1008 0.471036 38.7703 1.33267 37.7096L30.6237 2.14151C31.618 0.930909 33.0469 0.169608 34.596 0.0250844C36.145 -0.119439 37.6874 0.364654 38.8837 1.37087C40.0801 2.37708 40.8324 3.82299 40.9752 5.39051C41.118 6.95803 40.6396 8.51876 39.6453 9.72936L13.4591 41.5035L38.7665 73.2777C39.4829 74.1478 39.938 75.2074 40.0778 76.331C40.2177 77.4547 40.0366 78.5954 39.5559 79.6181C39.0752 80.6409 38.3151 81.5029 37.3654 82.1021C36.4157 82.7014 35.3163 83.0128 34.1972 82.9996Z"
                      fill="#009b9d"
                    />
                  </svg>
                </S.TextHeadingArrow>
              </S.TextHeading>
              <img
                className="mobile imgmobile"
                src={personal4}
                alt="treino on-line"
              />
              <S.Description revert>
                Metodologia original que utiliza diversos métodos de treinamento
                (treinamento funcional, pilates solo, musculação e aeróbios) em
                apenas uma aula, com fichas individualizadas de treino e
                atendimento de excelência.
                <br />
                <br />
                <strong>
                  Adequamos o espaço disponível ou planejamos a montagem de uma
                  academia em seu condomínio para a realização das atividades.
                </strong>
                <button onClick={() => history.push("/planos")} className="bt1">
                  Conheça os planos
                </button>
              </S.Description>
            </S.TextSide>
            <S.ImageSide>
              <img className="desktop" src={personal4} alt="treino on-line" />
            </S.ImageSide>
          </S.Modalidade>
          <hr className="mobile" />
          <S.Modalidade>
            <S.ImageSide>
              <img className="desktop" src={personal5} alt="treino on-line" />
            </S.ImageSide>
            <S.TextSide>
              <S.TextHeading>
                <S.TextHeadingArrow>
                  <svg
                    width="41"
                    height="83"
                    viewBox="0 0 41 83"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.1972 82.9996C33.322 83.0026 32.4572 82.8072 31.6665 82.4276C30.8758 82.048 30.1791 81.494 29.6278 80.8062L1.33267 45.2382C0.471036 44.1774 0 42.8469 0 41.4739C0 40.1008 0.471036 38.7703 1.33267 37.7096L30.6237 2.14151C31.618 0.930909 33.0469 0.169608 34.596 0.0250844C36.145 -0.119439 37.6874 0.364654 38.8837 1.37087C40.0801 2.37708 40.8324 3.82299 40.9752 5.39051C41.118 6.95803 40.6396 8.51876 39.6453 9.72936L13.4591 41.5035L38.7665 73.2777C39.4829 74.1478 39.938 75.2074 40.0778 76.331C40.2177 77.4547 40.0366 78.5954 39.5559 79.6181C39.0752 80.6409 38.3151 81.5029 37.3654 82.1021C36.4157 82.7014 35.3163 83.0128 34.1972 82.9996Z"
                      fill="#009b9d"
                    />
                  </svg>
                </S.TextHeadingArrow>
                <S.TextHeadingContent>
                  <h2>
                    Iniciação e <br />
                    Prática Esportiva
                  </h2>
                  <h3>Esportes e Atividades Lúdicas</h3>
                  <span>Grupos de no mínimo 6 pessoas</span>
                </S.TextHeadingContent>
              </S.TextHeading>
              <img
                className="mobile imgmobile"
                src={personal5}
                alt="treino on-line"
              />
              <S.Description>
                Atividade desenvolvida para atender crianças (iniciação
                esportiva) ou adultos (prática esportiva) ensinando fundamentos
                básicos, técnica/tática de vários esportes, além de jogos
                recreativos e atividades lúdicas.
                <br />
                <br />
                <strong>
                  Entre em contato e faça um orçamento para o seu condomínio
                </strong>
              </S.Description>
            </S.TextSide>
          </S.Modalidade>
          <br className="mobile" />
          <br className="mobile" />
        </section>

        <section className="landing-section2  cinza-section">
          <S.TestimonialWrapper>
            {/* <h2>DEPOIMENTOS</h2> */}
            <Carousel>
              <S.TestimonialInner>
                <h4>Maria Inês</h4>
                <Testimonial>
                  Frequento a academia "Projeto Viva+" há mais de três anos e
                  gosto muito! Os professores sempre muito atenciosos, didáticos
                  em suas explicações e dinâmicos. O ambiente acolhedor, sempre
                  higienizado e organizado. Consegui melhorar muito a postura, o
                  equilíbrio, a capacidade respiratória, a resistência física e
                  a consciência corporal. Super recomendo!!! Com a pandemia,
                  passei a realizar o treino Personal on-line, conseguindo assim
                  manter os benefícios dos treinos presenciais, com um
                  planejamento específico, dispondo o tempo todo de orientação
                  individualizada. Parabéns a toda equipe!!!
                </Testimonial>
              </S.TestimonialInner>
              <S.TestimonialInner>
                <h4>Josmar</h4>
                <Testimonial>
                  Meu nome é Josmar, tenho 57 anos e treino na academia "Projeto
                  Viva +", desde agosto de 2015, só tenho a agradecer pelos
                  resultados que ocorreram em meu corpo e na minha saúde,
                  anteriormente tinha dores nas costas e não tinha
                  condicionamento físico. Com os treinos orientados que englobam
                  musculação, pilates, aeróbico e outros exercícios montados sob
                  medida para atender as minhas necessidades e objetivos,
                  sumiram as dores nas costas e consegui um bom condicionamento
                  físico, desta forma ganhei mais disposição e qualidade de
                  vida. Parabéns a todos os profissionais da academia, são
                  excelentes, de alto nível, super dedicados, atenciosos e
                  acolhedores, conta com equipamentos de ótima qualidade,
                  possibilitando um excelente resultado. Com a pandemia, passei
                  a fazer os treinos on-line e gostei, assim mantive o ritmo da
                  academia, não perdendo nada, pois com o treino orientado, foi
                  possível manter o mesmo condicionamento, adaptando e
                  utilizando o que tenho em casa. Recomendo a todos.
                </Testimonial>
              </S.TestimonialInner>
              <S.TestimonialInner>
                <h4>Lilian Padilha</h4>
                <Testimonial>
                  Pensar no Projeto Viva+ me remete a três coisas que resumem a
                  excelência dessa equipe: Profissionalismo, Confiança e
                  Cuidado. Todos os orientadores físicos que ali trabalham são
                  extremamente profissionais e éticos ao lidar com os alunos.
                  Todo o trabalho é feito com o envolvimento e acompanhamento
                  constante desses profissionais. A confiança nessa equipe vem
                  do imenso cuidado que vai além do preparo físico do aluno. No
                  meu caso, venho de um histórico de lesões que, frequentemente,
                  me afastam do que mais gosto de fazer que é a corrida de rua.
                  No atendimento personalizado do Viva+, o trabalho comigo é
                  direcionado ao fortalecimento físico para viabilizar a minha
                  prática da corrida. Mesmo com idas e vindas, o treino físico
                  orientado está permitindo ampliar os meus limites e evoluir
                  nesse esporte que amo praticar. Já frequentei diversas
                  academias, desde grandes franquias até aquelas de bairro, e só
                  no Viva+ vi 100% de envolvimento da equipe com todos os
                  alunos. O trabalho deles é realmente diferenciado. Obrigada a
                  toda a equipe do Viva+.
                </Testimonial>
              </S.TestimonialInner>
            </Carousel>
          </S.TestimonialWrapper>
        </section>

        <footer className="landing-footer">
          <S.Direita src={direita} className="desktop" alt="apetrecho" />
          <div className="container">
            <img src={logo} className="logoLanding" alt="" />
            <S.SocialMedia>
              <a
                href="https://www.instagram.com/projetovivamaiss/"
                target="_blank"
              >
                <img src={instagramIcon} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/Projeto-Viva-Mais-1413255182251367"
                target="_blank"
              >
                <img src={facebookIcon} alt="Facebook" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5519998086658"
                target="_blank"
              >
                <img src={whatsappIcon} alt="Whatsapp" />
              </a>
            </S.SocialMedia>
            <span>contato@projetovivamais.com.br</span>
            <span>© 2021 Projeto Viva Mais</span>
            <ul className="landing-footer-socials"></ul>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Landing;
