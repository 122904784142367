import React, { useEffect, useState } from 'react'
import Api from '../../services/api'
import { parseJwt } from '../../services/auth'
import { Link, useHistory } from 'react-router-dom'
import { toDateTimeNoSeconds } from '../../helpers/formatHelper'
import XLSX from 'xlsx'
import { setAlertPropsAndShow } from '../../helpers/alertHelper'


function Users() {

  function smallestToBiggest(a, b) {
    return a.created_at - b.created_at;
  }
  let history = useHistory()
  const [users, setUsers] = useState([])
  const [deletedUser, setDeletedUser] = useState(false)

  useEffect(() => {
    getUsers()
  }, [deletedUser])

  
  const getUsers = () => {
    Api.get('./user')
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data)
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }

  const deleteUser = (id) => {
    const confirm = window.confirm("Tem certeza de que deseja deletar essa sequencia?");
    if(!confirm) {
      return
    }
    Api.delete(`/user/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setAlertPropsAndShow({ title: 'Sucesso', message: 'Usuário deletado com sucesso' })
          setDeletedUser(!deletedUser)
        }
      })
      .catch((error) => {
        setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
      })
  }
  

  const dowloadXLSX = (users) => {
    const formatData = users.map((user) => ({
      'ID': user.id,
      'Nome': user.name,
      'Email': user.email,
      'CPF': user.cpf,
      'Permissão': user.user_type === 'admin' ? 'Administrador' : 'Comum',
      'Telefone': user.telephone,
      'Último Login': toDateTimeNoSeconds(user.last_login),
      'Data de Criação': toDateTimeNoSeconds(user.createdAt),
      'Usuário Ativo': user.activated ? 'SIM' : 'NÃO',
    }))

    const workSheet = XLSX.utils.json_to_sheet(formatData)

    const workBook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Cadastro')

    XLSX.writeFile(workBook, 'relatorio_cadastro.xlsx')
  }

  return (
    <>
      <section className="admin-flex">
 
        {parseJwt().id == 1 ? (
          <Link className="novo" to={'/cadastro'}>
          Novo Cadastro
        </Link> 
        ) : null}
        
        <h3 className="table-title">Lista de Alunos</h3>
        <table cellspacing="0" cellpadding="0" className="users-table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              {/* <th>Instrutor</th> */}
              {/* <th>Criado em</th> */}
              <th>Ativo?</th>
            </tr>
          </thead>
          <tbody>
            {users.sort(function(a, b) {
  if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
 }).map((user) =>
              user.telephone == parseJwt().id || parseJwt().id == 1 ? (
                <tr className={user.user_type == "admin" ? "adminTr" : "" } key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  {/* <td>{user.telephone}</td> */}
                  {/* <td>{toDateTimeNoSeconds(user.createdAt)}</td> */}
                  <td>{user.activated ? 'SIM' : 'NÃO'}</td>
                  <td>
                    {user.user_type == "admin" ? null : (
                      <button 
                      onClick={() =>
                        history.push({ pathname: '/admin/editar/treino', user_id: user.id, name: user.name })
                      }
                    >
                      Ver Treino
                    </button>
                    )}
                    {user.user_type == "admin" ? null : (
                      <button 
                      onClick={() =>
                        history.push({ pathname: '/admin/editar/pdf', user_id: user.id, name: user.name })
                      }
                    >
                      Ver Lista PDF
                    </button>
                    )}
                    
                    {user.id == 1 ? null : (
                      <button className="button-delete" onClick={() => deleteUser(user.id)}>Deletar</button>
                    )}
            
                  </td>
                </tr>
              ) : null,
            )}
          </tbody>
        </table>
        <button onClick={() => dowloadXLSX(users)}>Baixar XLSX</button>
      </section>
    </>
  )
}

export default Users
