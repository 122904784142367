import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function UserConfirmation() {
  let history = useHistory()
  useEffect(() => {
    if (localStorage.getItem('userToken') || !window.location.href.split('%')[1])
      return history.push('/')

    setTimeout(() => {
      history.push('/')
    }, 2000)
  }, [])

  return (
    <>
      <section>
        <h2>SEU USUÁRIO FOI ATIVADO COM SUCESSO!</h2>
      </section>
    </>
  )
}

export default UserConfirmation
