import React, { useRef, useEffect, useState } from "react";
import Api from "../../services/api";
import { parseJwt } from "../../services/auth";
import data from "../../videoData.json";
import { FaClipboardList } from "react-icons/fa";
import {
  FaVideo,
  FaUser,
  FaListAlt,
  FaSquare,
  FaRedoAlt,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ModalVideo from "react-modal-video";
import LoadingSpin from "react-loading-spin";

import DEFAULT_IMG from "../../assets/imgs/default.png";
import atividades from "../../assets/imgs/atividades.png";
import rest from "../../assets/imgs/rest.png";

function Tabela() {
  const [trainings, setTrainings] = useState([]);
  const [currentTraining, setCurrentTraining] = useState([0]);
  const [userName, setUserName] = useState("");
  const [view, setView] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apiloading, setApiloading] = useState(true);

  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= 1) {
      setLoading(false);
    }
  };
  let history = useHistory();
  useEffect(() => {
    getTrainings();
    setApiloading(true);
  }, [currentTraining]);

  const getTrainings = () => {
    Api.get(`/training/${parseJwt().id}`)
      .then((response) => {
        if (response.data.length > 0) {
          setTrainings(response.data);
          setApiloading(false);
          console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) return setUserName(parseJwt().name);
  }, []);

  const logout = () => {
    localStorage.removeItem("userToken");

    return history.push("/");
  };
  return (
    <>
      {apiloading ? <div className="loadingdiv"></div> : null}
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={videoUrl}
        onClose={() => setOpen(false)}
      />

      <div className="videosHeader">
        <div className="title">
          <div>
            <FaClipboardList className="iconVideosHeader" />
            <h1> Fichas de Treino</h1>
          </div>
          <div>
            <FaUser className="iconVideosHeader desktop" />
            <strong className="desktop">
              {decodeURIComponent(escape(userName))}
            </strong>
            <button onClick={() => logout()}>Sair</button>
          </div>
        </div>
      </div>
      <div className="tabela-header">
        {trainings.map((training, i) => (
          <button
            className={`${
              trainings[currentTraining].name === training.name
                ? "active-sequence"
                : null
            } 
          `}
            key={training.id}
            onClick={() => setCurrentTraining([i])}
          >{`${training.name} `}</button>
        ))}
        <div className="changeView desktop">
          <FaListAlt
            size={32}
            onClick={() => setView(false)}
            className={`viewIcon ${view === false ? "active" : ""}`}
          />
          <FaSquare
            size={32}
            onClick={() => setView(true)}
            className={`viewIcon ${view === true ? "active" : ""}`}
          />
        </div>
      </div>
      <div className="changeView2 mobile">
        <FaListAlt
          size={32}
          onClick={() => setView(false)}
          className={`viewIcon ${view === false ? "active" : ""}`}
        />
        <FaSquare
          size={32}
          onClick={() => setView(true)}
          className={`viewIcon ${view === true ? "active" : ""}`}
        />
      </div>
      {!view
        ? trainings[currentTraining]?.Parts.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
          }).map((part) => (
            <div className="tabelaTraining" key={part.id}>
              <h2>{`${part.name} - ${part.time}`}</h2>
              <div className="descanso">
                {trainings[currentTraining]
                  ? trainings[currentTraining].time
                  : null}
              </div>
              <div className="tableWrapper" key={part.id}>
                <table className="responstable">
                  <thead>
                    <tr>
                      <th>Sequência</th>
                      <th className="exerciciosTh" data-th="Exercícios">
                        <span>Exercícios</span>
                      </th>
                      <th>Série/Repetições</th>
                      <th>Carga</th>
                      <th className="obsTh">Observações</th>
                    </tr>
                  </thead>
                  {part.Sequences?.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                  }).map((sequence) =>
                    sequence.Exercises?.map((exercise) => (
                      <tbody key={exercise.id}>
                        <tr className="sequenciaTr">
                          <td className="sequenciaTd">{sequence.name}</td>
                          {data.map((video) =>
                            video.id === exercise.video_id ? (
                              <>
                                {" "}
                                <td
                                  className="giftd desktop"
                                  onClick={() => {
                                    setOpen(true);
                                    setVideoUrl(
                                      `${process.env.PUBLIC_URL}${video.src}`
                                    );
                                  }}
                                  key={video.id}
                                >
                                  {video.name}
                                  <br />
                                  <span className="desktop">
                                    Clique para ver
                                  </span>
                                  <span className="mobile">
                                    clique para ver
                                  </span>
                                  <div className="gifModal desktop">
                                    <img
                                      onError={(e) => {
                                        e.target.src = DEFAULT_IMG;
                                      }}
                                      onLoad={imageLoaded}
                                      alt=""
                                      src={`${process.env.PUBLIC_URL}${video.src2}`}
                                    />
                                  </div>
                                </td>
                                <td className="giftd mobile">
                                  {video.name}
                                  <br />
                                  <span className="mobile">
                                    clique para ver
                                  </span>
                                  <div className="gifModal mobile">
                                    <FaVideo
                                      className="favideo"
                                      onClick={() => {
                                        setOpen(true);
                                        setVideoUrl(
                                          `${process.env.PUBLIC_URL}${video.src}`
                                        );
                                      }}
                                      key={video.id}
                                    />
                                    <img
                                      onError={(e) => {
                                        e.target.src = DEFAULT_IMG;
                                      }}
                                      onLoad={imageLoaded}
                                      alt=""
                                      src={`${process.env.PUBLIC_URL}${video.src2}`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <FaRedoAlt className="iconeView mobile" />{" "}
                                  {exercise.series}
                                </td>
                                <td className="desktop">{exercise.load}</td>
                                <td className="tdflex">
                                  <img
                                    className="iconeView mobile"
                                    src={atividades}
                                  />
                                  {exercise.load}
                                </td>
                                <td className="obsTd">
                                  {sequence.observation}
                                </td>
                                {console.log(sequence)}
                              </>
                            ) : null
                          )}
                        </tr>
                      </tbody>
                    ))
                  )}
                </table>
              </div>
            </div>
          ))
        : trainings[currentTraining]?.Parts.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
          }).map((part) => (
            <div className="tabelaView" key={part.id}>
              <h2>{`${part.name} - ${part.time}`}</h2>

              <div className="tableWrapperView" key={part.id}>
                {part.Sequences?.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                }).map((sequence, last) =>
                  sequence.Exercises?.map((exercise, i) => (
                    <>
                      <div
                        className="sequenciaItemViewWrapper"
                        key={exercise.id}
                      >
                        <div
                          className={`sequenciaItemView ${
                            i > 0 &&
                            sequence.Exercises[i].sequence_id ===
                              sequence.Exercises[i - 1].sequence_id
                              ? "same-sequence"
                              : ""
                          }`}
                        >
                          <hr />
                          <div className="itemHolder">
                            {data.map((video) =>
                              video.id === exercise.video_id ? (
                                <div
                                  className="trainModeGif"
                                  onClick={() => {
                                    setOpen(true);
                                    setVideoUrl(
                                      `${process.env.PUBLIC_URL}${video.src}`
                                    );
                                  }}
                                >
                                  <div className="sequenciaNameView">
                                    <h4>sequência</h4>
                                    {sequence.name}
                                  </div>
                                  <div className="gifNameView" key={video.id}>
                                    {video.name}{" "}
                                  </div>

                                  <img
                                    onError={(e) => {
                                      e.target.src = DEFAULT_IMG;
                                    }}
                                    onLoad={imageLoaded}
                                    alt=""
                                    src={`${process.env.PUBLIC_URL}${video.src2}`}
                                  />
                                  <div
                                    className="loading-spin"
                                    style={{ opacity: loading ? "1" : "0" }}
                                  >
                                    <LoadingSpin
                                      duration="2s"
                                      width="5px"
                                      timingFunction="ease-in-out"
                                      direction="alternate"
                                      size="50px"
                                      primaryColor="#024962"
                                      secondaryColor="#012946"
                                    />
                                  </div>
                                </div>
                              ) : null
                            )}
                            <div className="infoView">
                              <div>
                                <FaRedoAlt className="iconeView" />{" "}
                                {exercise.series}
                              </div>
                              <div>
                                <img className="iconeView" src={atividades} />
                                {exercise.load}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {i === sequence.Exercises.length - 1 ? (
                        <div className="descansoView">
                          <h3>INTERVALO</h3>
                          <img alt="" src={rest} />

                          <p>
                            {trainings[currentTraining]
                              ? trainings[currentTraining].time
                              : null}
                          </p>
                        </div>
                      ) : null}
                    </>
                  ))
                )}
              </div>
            </div>
          ))}
    </>
  );
}

export default Tabela;
