import React, { useEffect, useState } from 'react'
import { cpfMask, cellphoneMask } from '../helpers/MaskHelper'
import Api from '../services/api'
import { parseJwt } from '../services/auth'
import { setAlertPropsAndShow } from '../helpers/alertHelper'
import { useHistory } from 'react-router-dom'

function Register(){
    let history = useHistory()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [cpf, setCpf] = useState('')
    const [telephone, setTelephone] = useState('')
    const [password, setPassword] = useState('')
    const [admins, setAdmins] = useState([])


    const getAdmins = () => {
        Api.get('./user')
          .then((response) => {
            if (response.status === 200) {
             const users = response.data
             const admins = []
             users.forEach(element => {
               if (element.user_type === "admin") {
                element.password_hash = null
                
                admins.push(element)
               }
             });
             setAdmins(admins)
            }
          })
          .catch((error) => {
            setAlertPropsAndShow({ title: 'Ops!', message: error.response.data.error })
          })
      }

    useEffect(()=>{
        getAdmins()
        const userToken = localStorage.getItem('userToken')

        if(!userToken) return

        if(parseJwt().user_type !== 'admin') return history.push('/home')
    }, [history])

    const register = (e, name, email, confirmEmail, telephone, cpf, password) => {
        e.preventDefault()
        if((email !== confirmEmail)) return setAlertPropsAndShow({title: 'Ops!', message: 'Os emails não conferem.'})

        Api.post('/user', {
            name, email, telephone, cpf, password
        }).then(response => {
            if(response.status === 200){
                alertSuccessAndRedirectToLogin()
            }
        }).catch(error => {
            setAlertPropsAndShow({title: 'Ops!', message: error.response.data.error})
        })

    }

    const alertSuccessAndRedirectToLogin = () => {
        return setAlertPropsAndShow({title: 'Usuário cadastrado com sucesso!', message: 'Informe o e-mail e a senha para o aluno', onClickFunction: () => {history.push('/')}}) 
    }

    return(
        <>
        <section className="register">
            <form onSubmit={e => register( e, name, email, confirmEmail, telephone, cpf, password )}>
                <input type='text' placeholder='Nome' required value={name} onChange={e => setName(e.target.value)}/>
                <input type='text' placeholder='CPF' required value={cpf} onChange={e => setCpf(cpfMask(e.target.value))}/>
                <select id="instructor" name="instructor" defaultValue='' onChange={e => setTelephone(e.target.value)}>
                    <option value="">Selecione o professor:</option>
                    {admins.map((admin) => (
                         <option key={admin.id} value={admin.id}>{admin.name}</option>
                    ))}
                </select>
                {/* <input type='text' placeholder='Professor' autoComplete='no' required  maxLength={'15'} value={telephone} onChange={e => setTelephone(e.target.value)}/> */}
                <input type='email' placeholder='E-mail' required value={email} onChange={e => setEmail(e.target.value)}/>
                <input type='email' placeholder='Confirmação de e-mail' required value={confirmEmail} onChange={e => setConfirmEmail(e.target.value)}/>
                <input type='password' placeholder='Senha' required value={password} onChange={e => setPassword(e.target.value)}/>
                <button type='submit' value="submit" >Cadastrar</button>
            </form>
                <button onClick={history.goBack}>Voltar</button>
        </section>
        </>
    )
}

export default Register
