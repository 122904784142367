import React, { useEffect, useState } from 'react'
import { setAlertPropsAndShow } from '../helpers/alertHelper'
import api from '../services/api'
import pdfIcon from '../assets/imgs/pdf.svg'
import { parseJwt } from '../services/auth'
import { FaUser } from 'react-icons/fa'
import { FaRegFilePdf } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

function Archives(){
    const [pdfs, setPdfs] = useState([])
    const [userName, setUserName] = useState('')
    const [apiloading, setApiloading] = useState(true);

    let history = useHistory()

    useEffect(() => {
        if (localStorage.getItem('userToken')) return setUserName(parseJwt().name)
      }, [])
    
      const logout = () => {
        localStorage.removeItem('userToken')
    
        return history.push('/')
      }

    useEffect(() => {
        api.get(`/pdf/${parseJwt().id}`).then(response => {
            setPdfs(response.data)
          setApiloading(false)
        }).catch(error => {
            return setAlertPropsAndShow({
                title: 'Erro!',
                message: 'Não foi possivel buscar seus arquivos.'
            })
        })
    }, [])

    return(

            <div className="atividadesSection">
                    {apiloading ? <div className="loadingdiv"></div> : null}
                  <div className="videosHeader">
        <div className="title">
          <div>
            <FaRegFilePdf className="iconVideosHeader" />
            <h1>Arquivos</h1>
          </div>
          <div>
            <FaUser className="iconVideosHeader desktop" />
            <strong className="desktop">{decodeURIComponent(escape(userName))}</strong>
            <button onClick={() => logout()}>Sair</button>
          </div>
        </div>
      </div>
      <div className="archives-wrapper">
          
          {         pdfs.map(pdf => (
      <a href={pdf.pdf_path}>
          <div className="archives-row">
              <img src={pdfIcon} alt="" />
                <span>{pdf.pdf_title} </span>
                </div>
            </a>
          )        )
        }
                </div>
            </div>

    )
}
    export default Archives;