export const showAlert = () => {
    var alert = document.getElementById('globalAlert')
    if(!alert) return

    alert.style.display = 'flex'
}

export const hideAlert = () => {
    var alert = document.getElementById('globalAlert')
    if(!alert) return

    alert.style.display = 'none'
}

export const setAlertPropsAndShow = alertProps => {
    var alertTitle = document.getElementById('globalAlertTitle')
    var alertMessage = document.getElementById('globalAlertMessage')
    var alertButton = document.getElementById('globalAlertButton')
    if(!alertTitle || !alertMessage || !alertButton) return

    if(alertProps.onClickFunction){
        alertButton.addEventListener('click', () => {alertProps.onClickFunction()})
    }

    alertTitle.innerHTML = alertProps.title
    alertMessage.innerHTML = alertProps.message
    alertProps.buttonText ? alertButton.innerHTML = alertProps.buttonText : alertButton.innerHTML = 'Ok'

    showAlert()
}