import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { setAlertPropsAndShow } from '../../helpers/alertHelper'
import api from '../../services/api'
import { resolve } from "path";
const fs = require('browserify-fs');
const path = require("path");

function Pdf() {
    const history = useHistory()
    const inputFileRef = useRef(null)
    const [pdfs, setPdfs] = useState([])
    const [title, setTitle] = useState('')

    useEffect(() => {
        if(!history.location.user_id) return history.goBack()

        getPDF()
    }, [])

    const getPDF = () => {
        api.get(`/pdf/${history.location.user_id}`).then(response => {
            setTitle('')
            document.querySelector('input[type=file]').value = ''
            setPdfs(response.data)
        }).catch(error => {
            return setAlertPropsAndShow({
                title: 'Erro!',
                message: 'Não foi possivel buscar os PDFS referentes a esse usuário.'
            })
        })
    }

    const createPDF = (user_id) => {
        if(!title) return setAlertPropsAndShow({
            title: "Atenção!",
            message:"Informe um titulo para o PDF"
        })
        const formData = new FormData()

        formData.append('file', inputFileRef.current.files[0])
        formData.append('pdf_title', title)
        formData.append('user_id', user_id)
        api.post(`/pdf`, formData).then(response => {
            getPDF()
            return setAlertPropsAndShow({
                title: 'Sucesso!',
                message: 'PDF Criado com sucesso!'
            })
        }).catch(error => {
            return setAlertPropsAndShow({
                title: 'Erro!',
                message: 'Não foi possível criar PDF!'
            })
        })
    }

    const deletePdf = (id) => {
        api.delete(`/pdf/${id}`).then(response => {
            setTimeout(() => {
                getPDF()
            }, 500)
            return setAlertPropsAndShow({
                title: 'Sucesso!',
                message: 'PDF deletado com sucesso!'
            })
        }).catch(error => {
            return setAlertPropsAndShow({
                title: 'Erro!',
                message: error.response.data?.error
            })
        })
    }

    return (
        <>
         <section className="admin-flex">
         <button className="back-button" onClick={history.goBack}>Voltar</button>
        <div className="admin-pdf-title"></div>
        <h1 className="list-title">Enviar novo arquivo</h1>
        <form className="pdf-submit">
            <input required value={title} placeholder="Crie um título" onChange={e => setTitle(e.target.value)} />
            <input required type="file" ref={inputFileRef} accept="application/pdf" />
            <button type="button" onClick={() => createPDF(history.location.user_id)}>Enviar arquivo</button>
        </form>
        <h1 className="list-title">Lista de Arquivos</h1>
        <ul>
            
            {pdfs.map(pdf => (
                <div className="admin-pdf-list">
                    <a href={pdf.pdf_path}>{pdf.pdf_title ? pdf.pdf_title : pdf.pdf_path}</a>
                    <button onClick={() => deletePdf(pdf.id)}>Deletar</button>
                </div>
            ))}
        </ul>
        </section>
        </>
    )
}

export default Pdf