import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  padding: 20px 0px;
  background-color: #01465f;

  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
`;
export const HeaderWrapper = styled.div`
  background-color: #01465f;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1280px;
  padding: 0px 20px;
`;
export const Logo = styled.img`
  width: 200px;
`;
export const ImageBg = styled.img`
  width: 100%;
`;
export const HeaderButton = styled.button`
  background: ${(props) =>
    props.green
      ? "linear-gradient(179.54deg, #00B690 0.39%, #155F6F 235.62%)"
      : "transparent"};
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
`;
export const HeaderButtons = styled.div``;
export const Section = styled.section``;
export const Slider = styled.div`
  height: 600px;
  display: grid;
  place-items: center;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  background-color: #01465f;
`;
export const Container = styled.div``;
export const SliderLeft = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/imgs/bg.png");
`;
export const Esquerda = styled.img`
  position: absolute;
  left: -100px;
  transform: translateY(-120px);
`;
export const Direita = styled.img`
  position: absolute;
  height: 360px;
  right: -80px;
  bottom: 0;
`;
export const TestimonialWrapper = styled.div`
  width: 100%;
  padding: 10px 80px;
  display: grid;
  place-items: center;
  @media (max-width: 961px) {
    padding: 20px 10px;
  }
`;
export const TestimonialInner = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  place-items: center;

  background-color: #33333390;
  padding: 20px 20px;
  border-radius: 10px;
  h4 {
    font-size: 40px;
  }
  p {
    padding-top: 20px;
  }
  @media (max-width: 961px) {
    max-width: 100vw;
    width: 100%;
    h4 {
      font-size: 22px;
    }
    p {
      font-size: 10px;
    }
  }
`;
export const Modalidade = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  place-items: center;
  padding: 0px 20px;

  padding-bottom: 150px;
  width: calc(100vw - 200px);
  @media (max-width: 961px) {
    grid-template-columns: 1fr;
    width: 100%;

    padding: 0px 0px 0px;
    h2 {
      font-size: 24px;
      line-height: 32px;
      white-space: nowrap;
    }
    h3 {
      font-size: 15px;
      padding: 10px 0px;
    }
    span {
      font-size: 15px;
    }
  }
`;
export const TextSide = styled.div`
  span {
    font-style: italic;
  }
`;
export const Description = styled.div`
  max-width: 500px;
  button {
    margin: 40px auto 0px;
  }
  @media (max-width: 961px) {
    max-width: auto;
    padding: 20px 20px;
  }
  padding-top: 40px;
  line-height: 32px;
  text-align: center;
`;
export const TextHeading = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.revert ? "1fr auto" : "auto 1fr")};
  @media (max-width: 961px) {
    grid-template-columns: 1fr;
    width: auto;
    max-width: 100vw;
    padding: 20px;
  }
  grid-gap: 20px;
`;
export const TextHeadingArrow = styled.div`
  transform: ${(props) => props.revert && "scale(-1)"};
  @media (max-width: 961px) {
    display: none;
  }
`;
export const TextHeadingContent = styled.div`
  text-align: center;

  h2 {
    text-align: center;
  }
`;

export const ImageSide = styled.div`
  display: grid;
  place-items: center;
  img {
    border-radius: 10px;
    max-width: 500px;
    @media (max-width: 961px) {
      max-width: 80vw;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;

  img {
    margin: 30px 10px 15px;
    width: 30px;
  }
`;
