import React, { useEffect, useState } from 'react'
import { parseJwt } from '../services/auth'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import { FaClipboardList } from 'react-icons/fa'
import { FaUser } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

function Corrida() {
  const [userName, setUserName] = useState('')

  let history = useHistory()

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }
  useEffect(() => {
    if (localStorage.getItem('userToken')) return setUserName(parseJwt().name)
  }, [])

  const logout = () => {
    localStorage.removeItem('userToken')

    return history.push('/')
  }
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }
  function nextPage() {
    changePage(1);
  }

  return (
    <div className="atividadesSection training">
      <div className="videosHeader">
        <div className="title">
          <div>
            <FaClipboardList className="iconVideosHeader" />
            <h1>Planilha de Corrida</h1>
          </div>
          <div>
            <FaUser className="iconVideosHeader desktop" />
            <strong className="desktop">{decodeURIComponent(escape(userName))}</strong>
            <button onClick={() => logout()}>Sair</button>
          </div>
        </div>
      </div>
      <Document className="canvas"
        file={process.env.PUBLIC_URL + 'pdfs/pdf.pdf'}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="pdf-buttons">
        <p>
          Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Anterior
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Próxima
        </button>
      </div>
    </div>
  )
}

export default Corrida
